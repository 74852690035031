.form-field__input {
  position: relative;

  display: block;
  width: 100%;
  height: auto;
  padding: 7px 15px;
  margin-bottom: 20px;

  font: inherit;
  color: #000000;

  border: 1px solid #777777;
  border-radius: 2px;
  box-shadow: 0 0 0 1px rgba(0,0,0,0);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  box-sizing: border-box;
}

.form-field__label {
  display: block;
  margin-bottom: 5px;

  text-align: left;
  font-size: 12px;
  line-height: 1;
  color: #777777;
}

%fieldset-reset {
  border: 0;
  padding: 0;
  margin: 0;
  min-width: 0;
}

%list-reset {
  margin: 0;
  padding: 0;

  list-style: none;
}

%button-reset {
  padding: 0;

  font-family: inherit;

  border: 0;
  border-radius: 0;
  background: transparent;

  cursor: pointer;

  appearance: none;
}
